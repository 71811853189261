<template>
  <div>
    <!-- 超商C2C專用 -->
    <div v-if="isCvsDeliveryType" class="grey--text text--darken-2 body-2">
      <p>超商C2C物流單必須符合綠界規範</p>
      <p>詳細材積、重量規範</p>
      <p>請參閱綠界官方網站的<a href="https://www.ecpay.com.tw/IntroTransport/Guide" target="_blank">物流寄件教學>物流進退貨規範手冊(超商C2C)</a></p>
      <p>確認符合各家超商規範後</p>
      <p>即可按下"確認"按鈕建立物流單</p>
    </div>

    <!-- 宅配專用 -->
    <div v-if="isHomeDeliveryType">
      <div class="grey--text text--darken-2 body-2 mb-4">
        <p>綠界宅配類型物流(黑貓、宅配通)注意事項如下: </p>
        <p>
          <ul>
            <li :class="{ 'red--text': sizeError }">冷藏、冷凍配送材積不可超過150cm</li>
            <li>宅配通(ECAN)只能用常溫配送</li>
          </ul>
        </p>

        <p>詳細規則可參閱綠界官方網站的<a href="https://www.ecpay.com.tw/IntroTransport/Guide" target="_blank">物流寄件教學</a></p>
      </div>

      <v-select
        dense
        outlined
        class="mb-2"
        :label="$t('ec.data.delivery.size')"
        :items="sizeList"
        v-model="params.size"
      ></v-select>

      <v-select
        dense
        outlined
        class="mb-2"
        :label="$t('ec.data.delivery.temperature')"
        :items="temperatureList"
        :disabled="disabledTemperature"
        v-model="params.temperature"
      ></v-select>

      <v-select
        dense
        outlined
        class="mb-2"
        :label="$t('ec.data.delivery.period')"
        :items="periodList"
        v-model="params.period"
      ></v-select>
    </div>

  </div>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    popupConfig: Object,
  },
  data: (vm) => ({
    params: {
      temperature: vm.$deliveryTemperature[0],
      size: vm.$deliverySizeOptions[0],
      period: vm.$deliveryPeriod[0],
    },
  }),
  computed: {
    // 非常溫溫層不可使用150cm
    sizeError() {
      if(this.params.temperature === this.$deliveryConstants.TEMPERATURE_NORMAL) return false
      if(this.params.size < 150) return false
      return true
    },
    order() {
      return this.popupConfig.order
    },
    // 宅配通(ECAN)只能用常溫配送
    disabledTemperature() {
      return this.deliveryType === this.$deliveryConstants.TYPE_HOME_ECAN
    },
    temperatureList() {
      return this.$deliveryTemperature.map(item => ({
        text: this.$t(`ec.data.delivery.temperature.${item}`),
        value: item,
      }))
    },
    sizeList() {
      return this.$deliverySizeOptions.map(item => ({
        text: `${item}cm`,
        value: item,
      }))
    },
    periodList() {
      return this.$deliveryPeriod.map(item => ({
        text: this.$t(`ec.data.delivery.period.${item}`),
        value: item,
      }))
    },
    deliveryType() {
      return this.order.delivery.type
    },
    isCvsDeliveryType() {
      return this.$isCvsDeliveryType(this.deliveryType)
    },
    isHomeDeliveryType() {
      return this.$isHomeDeliveryType(this.deliveryType)
    },
  },
  watch: {
    params: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit('updateData', window.eagleLodash.cloneDeep(this.params))
      },
    }
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
